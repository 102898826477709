body {
  margin: 0;
  font-family: "Chivo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a {
  color: #b8b8b8;
  text-decoration: none;
}

body a:hover {
  text-decoration: underline;
}

button {
  font-family: "Chivo", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
